import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | СпецЦентр Автообслуговування та Шиномонтажу
			</title>
			<meta name={"description"} content={"Історія нашої автомайстерні - Чому саме ми ваш надійний партнер у світі автомобільного обслуговування!"} />
			<meta property={"og:title"} content={"About | СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:description"} content={"Історія нашої автомайстерні - Чому саме ми ваш надійний партнер у світі автомобільного обслуговування!"} />
			<link rel={"shortcut icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileColor"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0" background="--color-greyD1">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Про Нас
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Ця історія розпочалася лише кілька років тому, коли група друзів із спільною страстю до автомобілів та автосервісу вирішили зробити щось особливе. Вони завжди мріяли про власне місце, де автовласники могли би отримати найкращий сервіс, який забезпечує безпеку та надійність на дорозі.
				<br />
				<br />
				Початково це був скромний гараж, де друзі власноруч виконували ремонт та обслуговування автомобілів. Протягом небагатьох років їх репутація зростала, і з ними приєднувалися інші фахівці, які ділили їхню страсть до автосервісу. Популярність автосервісу стрімко зросла завдяки відмінному обслуговуванню та відвертому ставленню до клієнтів.
				<br />
				<br />
				Сьогодні, це місце відоме як СпецЦентр Автообслуговування та Шиномонтажу, що служить місцем, де автовласники можуть довірити свої автомобілі професіоналам, які завжди готові вирішити будь-які технічні або шиномонтажні завдання. Група друзів залишається вірною своєму завданню - надавати найкращий сервіс та дбати про безпеку і комфорт кожного клієнта. Їхнім трудом і страстю створено місце, де автомобільні мрії стають реальністю.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" background="rgba(0, 0, 0, 0) url(https://uatrestinlive.net/images/about1.jpg) 0% 0% /cover repeat scroll padding-box">
					<Text margin="0px 0px 0px 0px" width="320px" height="450px">
						{"  "}
					</Text>
				</Box>
				<Box padding="10px" background="rgba(0, 0, 0, 0) url(https://uatrestinlive.net/images/about2.jpg) 0% 0% /cover repeat scroll padding-box">
					<Text margin="0px 0px 0px 0px" width="320px" height="450px">
						{"  "}
					</Text>
				</Box>
				<Box padding="10px" background="rgba(0, 0, 0, 0) url(https://uatrestinlive.net/images/about3.jpg) 0% 0% /cover repeat scroll padding-box">
					<Text margin="0px 0px 0px 0px" width="320px" height="450px">
						{"  "}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Advantages />
		<Components.Contact />
		<Components.FootedDesc />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523de57e2dd3a0022204b82"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});